import { useState } from "react"

let TrackOrder = () => {
    let [phone, setPhone] = useState("+62");

    const handleFocus = () => {
        if (!phone.startsWith("+62")) {
            setPhone("+62");
        }
    };

    const handlePrefix = (e) => {
        let value = e.target.value;

        if (!value.startsWith("+62")) {
            value = "+62" + value.replace(/^\+*/, '');
        }

        value = value.replace(/[^\d+]/g, '');

        let prefix = "+62";
        let numberPart = value.slice(3);

        if (numberPart.startsWith("0")) {
            numberPart = numberPart.replace(/^0+/, '');
        }

        const formattedPhone = prefix + numberPart;
        setPhone(formattedPhone);
    };

    return (
        <section id="hero">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 order-2 order-lg-1 d-flex align-items-center pe-5">
                            <div data-aos="zoom-out">
                                <h1>Track Your <span>Order!</span></h1>
                                <div class="form-floating mb-3">
                                    <input type="text" 
                                        class="form-control product-corner bg-light fs-6" 
                                        id="invoiceNumber"
                                        placeholder=""
                                        value={phone}
                                        onFocus={handleFocus}
                                        onInput={handlePrefix}
                                        onChange={handlePrefix} />
                                    <label for="invoiceNumber">+628XXXXXXXXX</label>
                                </div>
                                <a href={`/history/${phone}`} id="submit"
                                    class="btn btn-outline-primary product-corner text-white fw-bold px-4 py-2 mt-2"><i class="bi bi-search me-2"></i> Track Order</a>
                            </div>
                        </div>
                        <div class="col-lg-4 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
                            <img src="https://trackercdn.com/cdn/tracker.gg/valorant/db/agents/sage_portrait.png"
                                class="img-fluid animated" alt="" />
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default TrackOrder;