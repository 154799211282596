import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_URL } from "../../../static/environment.js"
import "./index.css"

const Profile = () => {
  // const [username, setUsername] = useState("");
  const [username, setUsername] = useState(() => sessionStorage.getItem("username") || "");
  const [usernameChange, setUsernameChange] = useState(() => sessionStorage.getItem("username") || "");
  const [role, setRole] = useState(() => sessionStorage.getItem("role") || "");
  const [phoneNumber, setPhoneNumber] = useState(() => sessionStorage.getItem("phoneNumber") || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [activeMenu, setActiveMenu] = useState('Akun');

  const [activeTab, setActiveTab] = useState('profile');

  const criteria = [
    { id: 'length', label: 'Minimal 8 karakter', isValid: newPassword.length >= 8 },
    { id: 'uppercase', label: 'Minimal 1 huruf kapital', isValid: /[A-Z]/.test(newPassword) },
    { id: 'number', label: 'Minimal 1 angka', isValid: /[0-9]/.test(newPassword) },
    { id: 'symbol', label: 'Minimal 1 simbol', isValid: /[!@#$%^&*]/.test(newPassword) }
  ];

  const isPasswordValid = criteria.every((criterion) => criterion.isValid);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('isLoggedIn');

    window.location.href = "/"
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    if (usernameChange === "") {
      alert('nama lengkap kosong');
      return;
    }

    if (phoneNumber === "") {
      alert('nomor telepon kosong');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/update-profile`, {
        name: usernameChange,
        phoneNumber
      });

      if (response.data.message === "Update Berhasil") {
        sessionStorage.setItem("username", usernameChange)
        setUsername(usernameChange)
        window.alert("update profile berhasil")
      } else {
        window.alert("update profile gagal")
      }
    } catch (error) {
      alert('Update profile gagal. Silakan coba lagi.');
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (confirmNewPassword !== newPassword) {
      window.alert("new password not match")
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/update-password`, {
        username: phoneNumber,
        oldPassword,
        newPassword
      });

      if (response.data.message === "Update Berhasil") {
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("phoneNumber");
        sessionStorage.removeItem("role");

        window.location.href = "/"
      }

      if (response.data.message === "Update Gagal") {
        window.alert("update password gagal")
      }
    } catch (error) {
      alert('Update password gagal. Silakan coba lagi.');
    }
  };

  return (
    <section id="hero">
      <div className="container py-5">
        <div data-aos="zoom-out">
          <div className='row'>
            <div className='col-lg-3'>
              <div className="card product-corner">
                <div className="card-body">
                  <ul>
                    <li
                      className={activeMenu === 'Akun' ? 'active' : ''}
                      onClick={() => handleMenuClick('Akun')}
                    >
                      Akun
                    </li>
                    <li
                      className={activeMenu === 'Membership' ? 'active' : ''}
                      onClick={() => handleMenuClick('Membership')}
                    >
                      Membership
                    </li>
                    <li
                      className={activeMenu === 'Riwayat Transaksi' ? 'active' : ''}
                      onClick={() => handleMenuClick('Riwayat Transaksi')}
                    >
                      Riwayat Transaksi
                    </li>
                    <li
                      className={activeMenu === 'Riwayat Mutasi' ? 'active' : ''}
                      onClick={() => handleMenuClick('Riwayat Mutasi')}
                    >
                      Riwayat Mutasi
                    </li>
                    <li
                      onClick={() => handleLogout()}
                    >
                      Keluar
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-9'>
              {activeMenu === "Akun" ? (
                <>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <button className="btn btn-primary rounded-circle" style={{ width: '50px', height: '50px' }}>
                              {sessionStorage.getItem("username").charAt(0)}
                            </button>
                            <div className="ml-3" style={{ marginLeft: '10px' }}>
                              <h5>{sessionStorage.getItem("username")}</h5>
                              <p>{sessionStorage.getItem("role")}</p>
                            </div>
                          </div>
                          <button className="btn btn-outline-primary" onClick={() => setActiveMenu("Edit Profile")}>Edit Profile</button>
                        </div>

                        <hr style={{ opacity: 0.25, margin: '0 19px' }} />

                        <div className="d-flex align-items-center" style={{ marginTop: '20px', marginLeft: '19px', marginBottom: '20px' }}>
                          <i className="bi bi-phone fs-5"></i>
                          <p className="ml-2 mb-0 fs-5">{sessionStorage.getItem("phoneNumber")}</p>
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div>
                            <h5>Saldo</h5>
                            <strong><h3>Rp 1.000.000</h3></strong>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-success">Top Up</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="card" style={{
                        backgroundImage: 'url("https://momomlbb.com/images/logo-transparent.png")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        color: 'white',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: 'rgba(173, 216, 230, 0.5)'
                      }}>
                        <div className="card-body" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '15px' }}>
                          <p>Dapatkan harga lebih murah dengan Upgrade Membership!</p>
                          <button className="btn btn-primary blink-btn" onClick={() => setActiveMenu("Membership")}>Upgrade Sekarang</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <h3 className='text-white'>Pesanan Saya</h3>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="row">
                          {/* Kategori Belum Dibayar */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Belum Dibayar</p>
                          </div>

                          {/* Kategori Pending */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Pending</p>
                          </div>

                          {/* Kategori Success */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Success</p>
                          </div>

                          {/* Kategori Expired */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Expired</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
              ) : activeMenu === "Membership" ? (
                <>
                  {/* Konten untuk Membership */}
                  <h1 className='text-dark'>Membership</h1>
                </>
              ) : activeMenu === "Riwayat Transaksi" ? (
                <>
                  {/* Konten untuk Riwayat Transaksi */}
                  <h1 className='text-dark'>Riwayat Transaksi</h1>
                </>
              ) : activeMenu === "Riwayat Mutasi" ? (
                <>
                  {/* Konten untuk Riwayat Mutasi */}
                  <h1 className='text-dark'>Riwayat Mutasi</h1>
                </>
              ) : activeMenu === "Edit Profile" ? (
                <>
                  <div class="account-settings" style={{ marginBottom: '20px' }}>
                    <div onClick={(e) => setActiveMenu("Akun")} style={{ cursor: "pointer" }}>
                      <svg
                        stroke="currentColor"
                        fill="white"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
                      </svg>
                    </div>
                    <div class="text text-white" style={{ marginLeft: '10px' }}>Pengaturan Akun</div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <button className="btn btn-primary rounded-circle" style={{ width: '50px', height: '50px' }}>
                              {username.charAt(0)}
                            </button>
                            <div className="ml-3" style={{ marginLeft: '10px' }}>
                              <h5>{username}</h5>
                              <p>{role}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="account-settings">
                        <button className="profile-btn" onClick={() => handleTabClick('profile')}>
                          <i className="bi bi-person-circle"></i> Profile
                        </button>
                        <button className="password-btn" onClick={() => handleTabClick('password')}>
                          <i className="bi bi-lock"></i> Password
                        </button>
                      </div>

                      {activeTab === 'profile' && (
                        <div className="card mb-3">
                          <div className="card-body d-flex flex-column">
                            <form onSubmit={(e) => handleProfileSubmit(e)}>
                              <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Nama Lengkap</label>
                                <input
                                  type="text"
                                  id="fullName"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan nama lengkap"
                                  value={usernameChange}
                                  onChange={(e) => setUsernameChange(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="phoneNumber" className="form-label">Nomor Handphone</label>
                                <input
                                  type="tel"
                                  id="phoneNumber"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan nomor handphone"
                                  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                  disabled
                                />
                              </div>
                              <button type="submit" className="btn btn-primary rounded-pill shadow-sm">
                                Update Profile
                              </button>
                            </form>
                          </div>
                        </div>
                      )}

                      {activeTab === 'password' && (
                        <div className="card mb-3">
                          <div className="card-body d-flex flex-column">
                            <form onSubmit={(e) => handleChangePassword(e)}>
                              <div className="mb-3">
                                <label htmlFor="oldPassword" className="form-label">Password Lama</label>
                                <input
                                  type="password"
                                  id="oldPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan password lama"
                                  value={oldPassword}
                                  onChange={(e) => setOldPassword(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">Password Baru</label>
                                <input
                                  type="password"
                                  id="newPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan password baru"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                  required
                                />
                                <ul className="password-criteria">
                                  {criteria.map((criterion) => (
                                    <li
                                      key={criterion.id}
                                      className={criterion.isValid ? 'text-success' : 'text-danger'}
                                    >
                                      {criterion.label}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Konfirmasi Password</label>
                                <input
                                  type="password"
                                  id="confirmPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Konfirmasi password baru"
                                  value={confirmNewPassword}
                                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                                  required
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary rounded-pill shadow-sm"
                                disabled={!isPasswordValid}
                              >
                                Update Password
                              </button>

                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Konten default jika tidak ada yang cocok */}
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    </section>

  );
};

export default Profile;
