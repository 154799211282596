import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../static/environment.js"

let Landing = () => {
    const [category, setCategory] = useState([]);
    const [myProduct, setMyProduct] = useState([]);
    const [banner, setBanner] = useState([]);

    useEffect(() => {
        axios.get(`${API_URL}/parameter/image`)
            .then(function (response) {
                setBanner(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/category`)
            .then(function (response) {
                setCategory(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/my-product/`) // Ganti dengan endpoint API yang sesuai
            .then(response => {
                setMyProduct(response.data);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, [])

    return (
        <>
            <section id="hero">
                <div className="container">
                    <div className="row">
                        <section id="top-up-head" className="top-up-head">
                            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {banner.map((data, index) => {
                                        return (
                                            <button type="button" data-bs-target='#carouselExampleCaptions' data-bs-slide-to={index} className={index === 0 ? 'active' : ''}
                                                aria-current={index === 0 ? 'true' : 'false'} aria-label={'Slide ' + (index + 1)}>
                                            </button>
                                        )
                                    })}
                                </div>
                                <div className="carousel-inner">
                                    {banner.map((data, index) => {
                                        return (
                                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <img src={data.value_} className="set-responsive" />
                                            </div>
                                        )
                                    })}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                                    data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                                    data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
                <svg
                    className="hero-waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                >
                    <defs>
                        <path
                            id="wave-path"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#ECF7F3" />
                    </g>
                </svg>
            </section>

            <main id="main">
                <section id="top-up" className="top-up">
                    <div className="container">
                        <div id="loadingSpinner" className="loading-spinner"></div>
                        <ul className="nav nav-tabs" id="tabs" role="tablist">
                            {category.map(x => {
                                let converted = x.name.toLowerCase().replace(/\s+/g, '-');
                                return ((x.active && x.sequence === 1) ?
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link py-3 active" id={converted + "-tab"} data-bs-toggle="tab" data-bs-target={"#" + converted + "-tab-pane"}
                                            type="button" role="tab" aria-controls={converted + "-tab-pane"} aria-selected="true">{x.name}</button>
                                    </li>
                                    :
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link py-3" id={converted + "-tab"} data-bs-toggle="tab" data-bs-target={"#" + converted + "-tab-pane"}
                                            type="button" role="tab" aria-controls={converted + "-tab-pane"} aria-selected="true">{x.name}</button>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="tab-content" id="tabsContent">
                            {category.map(category => {
                                const converted = category.name.toLowerCase().replace(/\s+/g, '-');
                                const isActive = category.sequence === 1;
                                const filteredProducts = myProduct.filter(product => product.category.id === category.id);

                                return (
                                    <div key={category.id} className={`tab-pane fade ${isActive ? 'show active' : ''}`}
                                        id={`${converted}-tab-pane`} role="tabpanel"
                                        aria-labelledby={`${converted}-tab`} tabIndex="0">
                                        <div className="row g-3 aos-init aos-animate" data-aos="fade-left" id={converted}>
                                            {filteredProducts
                                                .sort((a, b) => a.position - b.position)
                                                .map(product => (
                                                <div className="col-lg-2 col-md-3 col-sm-4 col-4" key={product.id}>
                                                    <div className="top-up-item aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                                                        <a href={"/order/" + product.slug} className="top-up-lightbox d-flex align-items-end">
                                                            <img src={product.logo.path} alt={product.title} className="img-fluid" />
                                                            <div className="text-image px-3 pb-3">
                                                                <div className="text-title fw-bold">{product.title}</div>
                                                                <div className="text-subtitle text-white opacity-50">{product.sub_title}</div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Landing;