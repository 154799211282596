import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './component/pages/Layout';
import Landing from './component/pages/Landing';
import Order from './component/pages/Order';
import TrackOrder from './component/pages/TrackOrder';
import History from './component/pages/History';
import Payment from './component/pages/Payment';
import NotFound from './component/pages/Error/NotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from './component/pages/Profile';
import PrivateRoute from './component/pages/Private';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path="order/:item" element={<Order />} />
        <Route path="track-order" element={<TrackOrder />} />
        <Route path="history/:phone" element={<History />} />
        <Route path="history/:phone/:invoice" element={<Payment />} />
        <Route
            path="profile"
            element={<PrivateRoute element={<Profile />} />}
          />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
