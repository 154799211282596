import { Outlet } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../../static/environment.js"
import { titleTab } from "../../../static/globalSettings.js";
import "./index.css"

let Layout = () => {
    const [logoHeader, setLogoHeader] = useState({});
    const [logoFooter, setLogoFooter] = useState({})
    const [socialMedia, setSocialMedia] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
    const [isOtpOpen, setIsOtpOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dropdownRef = useRef(null);
    const loginRef = useRef(null);
    const registerRef = useRef(null);

    const [resendTimer, setResendTimer] = useState(60);

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const criteria = [
        { id: 'length', label: 'Minimal 8 karakter', isValid: password.length >= 8 },
        { id: 'uppercase', label: 'Minimal 1 huruf kapital', isValid: /[A-Z]/.test(password) },
        { id: 'number', label: 'Minimal 1 angka', isValid: /[0-9]/.test(password) },
        { id: 'symbol', label: 'Minimal 1 simbol', isValid: /[!@#$%^&*]/.test(password) }
    ];

    const isPasswordValid = criteria.every((criterion) => criterion.isValid);

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendTimer]);

    const handleResendOtp = () => {
        setResendTimer(60);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await axios.post(`${API_URL}/user/authentication/member`, {
            username: phoneNumber,
            password,
        });

        if (response.data.message === "Login Success") {
            setUsername(response.data.data.user.name);
            setIsLoggedIn(true);
            sessionStorage.setItem('username', response.data.data.user.name);
            sessionStorage.setItem('phoneNumber', response.data.data.user.phoneNumber);
            sessionStorage.setItem('role', response.data.data.role.name);
            sessionStorage.setItem('isLoggedIn', true);
            closeLoginModal();
        } else {
            alert("login gagal;")
        }
    };

    const handleLogout = () => {
        setUsername('');
        setIsLoggedIn(false);
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('isLoggedIn');
        setIsDropdownVisible(false);

        window.location.href = "/"
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(true);
        setTimeout(() => setIsDropdownOpen(true), 100)
    };

    const openLoginModal = () => {
        setIsLoginModalVisible(true);
        setTimeout(() => setIsLoginOpen(true), 100);
    };

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert('Password dan konfirmasi password tidak cocok.');
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/user/registration`, {
                name,
                phoneNumber,
                password,
            });

            setIsRegisterModalVisible(false);
            setIsOtpModalVisible(true)
            setIsOtpOpen(true);
        } catch (error) {
            alert('Pendaftaran gagal. Silakan coba lagi.');
        }
    };

    const closeLoginModal = () => {
        setIsLoginOpen(false);
        setTimeout(() => setIsLoginModalVisible(false), 100);
    };

    const openRegisterModal = () => {
        closeLoginModal();
        setIsRegisterModalVisible(true);
        setTimeout(() => setIsRegisterOpen(true), 100);
    };

    const closeRegisterModal = () => {
        setIsRegisterOpen(false);
        setTimeout(() => setIsRegisterModalVisible(false), 100);
    };

    const switchToLoginModal = () => {
        closeRegisterModal();
        setTimeout(() => openLoginModal(), 100);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownVisible(false);
        }

        if (loginRef.current && !loginRef.current.contains(e.target)) {
            setIsLoginModalVisible(false);
        }

        if (registerRef.current && !registerRef.current.contains(e.target)) {
            setIsRegisterModalVisible(false);
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert('Password dan konfirmasi password tidak cocok.');
            return;
        }

        setErrorMessage('');

        if (otp.length !== 6) {
            setErrorMessage('OTP harus terdiri dari 6 angka.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await axios.post(`${API_URL}/user/verification/${otp}/${phoneNumber}`);

            if (response.data.message === "Verified") {
                alert(`berhasil diverifikasi!`);
                setIsSubmitting(false);
                window.location.href = "/";
            } else {
                alert(`otp salah`);
            }
        } catch (error) {
            alert('Verifikasi gagal. Silakan coba lagi.');
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 6 && /^\d*$/.test(value)) {
            setOtp(value);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const storedUsername = sessionStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        axios.get(`${API_URL}/social-media`)
            .then(function (response) {
                setSocialMedia(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/LOGO-FOOTER`)
            .then(function (response) {
                setLogoFooter(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/LOGO-HEADER`)
            .then(function (response) {
                setLogoHeader(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/PHONE_NUMBER`)
            .then(function (response) {
                const contactInfoList = document.getElementById("contactInfo");

                const phoneNumberItem = document.createElement("li");
                phoneNumberItem.innerHTML = `<i class="bi bi-chevron-right me-1"></i><a href="https://wa.me/62${response.data.value_}" id="phoneInfo">${response.data.value_}</a>`;

                axios.get(`${API_URL}/parameter/by-category/settings`)
                    .then(function (responseData) {
                        let emailItem, addressItem;

                        responseData.data.forEach((value) => {
                            if (value.id === "FOOTER_EMAIL") {
                                emailItem = document.createElement("li");
                                emailItem.innerHTML = `<i class="bi bi-chevron-right me-1"></i>${value.value_}`;
                            }
                            if (value.id === "FOOTER_ADDRESS") {
                                addressItem = document.createElement("li");
                                addressItem.innerHTML = `<i class="bi bi-chevron-right me-1"></i>${value.value_}`;
                            }
                        });

                        contactInfoList.appendChild(phoneNumberItem);
                        contactInfoList.appendChild(emailItem);
                        contactInfoList.appendChild(addressItem);
                    })
                    .catch(function (error) {
                        window.alert(error);
                    })
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/APPLICATION_NAME`)
            .then(function (response) {
                titleTab(response.data.value_)
            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/FAVICON`)
            .then(function (response) {
                const favicon = document.getElementById("favicon");

                favicon.href = response.data.value_;
            })
            .catch(function (error) {
                window.alert(error);
            })
    }, [])

    return (
        <>
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex justify-content-between">
                    <div className="logo d-flex align-items-center">
                        <a href="/">
                            <img src={logoHeader.value_} className="me-3" alt="Logo" />
                        </a>
                    </div>
                    <nav id="navbar" className={`navbar ${isOpen ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className="nav-link fw-bold scrollto active" href="/">Home</a></li>
                            <div className="button-auth-mobile mt-2 mx-3">
                                <a href="/track-order"
                                    className="btn btn-outline-secondary justify-content-center fw-bold product-corner mb-2"><i
                                        className="bi bi-search me-2"></i> Track Order</a>
                                {/* {!isLoggedIn ? (
                                    <>
                                        <a onClick={openLoginModal}
                                            className="btn btn-primary justify-content-center text-white product-corner btn-mobile px-3 mb-2">Login</a>
                                        <a onClick={openRegisterModal} className="btn btn-primary justify-content-center product-corner btn-mobile px-3">Register</a>
                                    </>) : (
                                    <>
                                        <a className="btn btn-primary justify-content-center fw-bold product-corner px-3" onClick={toggleDropdown}>
                                            {username.toUpperCase()}
                                        </a>
                                        {isDropdownVisible && (
                                            <div style={{ position: 'relative', marginTop: "5px" }}>
                                                <div className={`dropdown-menu ${isDropdownOpen ? 'fade-in' : 'fade-out'}`} ref={dropdownRef}>
                                                    <a href="/profile" className="menu-item">Profile</a>
                                                    <a className="menu-item" onClick={handleLogout}>Keluar</a>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )} */}
                            </div>
                        </ul>
                        <i className={`bi ${isOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={handleToggle}></i>
                    </nav>
                    <div className="button-auth">
                        <a href="/track-order" id="track-order"
                            className="btn btn-outline-white rounded text-white product-corner px-3 me-2"><i className="bi bi-search me-2"></i>
                            Track Order</a>
                        {/* {!isLoggedIn ? (
                            <>
                                <a onClick={openLoginModal}
                                    className="btn btn-outline-primary text-white product-corner btn-mobile px-3 me-2">Login</a>
                                <a onClick={openRegisterModal} className="btn btn-primary product-corner btn-mobile px-3">Register</a>
                            </>) : (
                            <>
                                <a className="btn btn-primary text-white product-corner btn-mobile px-3 me-2" onClick={toggleDropdown}>
                                    {username.charAt(0).toUpperCase()}
                                </a>
                                {isDropdownVisible && (
                                    <div style={{ position: 'relative', marginTop: "5px" }}>
                                        <div className={`dropdown-menu ${isDropdownOpen ? 'fade-in' : 'fade-out'}`} ref={dropdownRef}>
                                            <a href="/profile" className="menu-item">Profile</a>
                                            <a className="menu-item" onClick={handleLogout}>Keluar</a>
                                        </div>
                                    </div>
                                )}
                            </>
                        )} */}
                    </div>
                </div>
            </header >

            <Outlet />

            {isLoginModalVisible && (
                <div className={`modal-overlay ${isLoginOpen ? 'fade-in' : 'fade-out'}`}>
                    <div className={`modal-box ${isLoginOpen ? 'zoom-in' : 'zoom-out'}`} ref={loginRef}>
                        <h2 className="modal-title">Login</h2>
                        <form className="modal-form" onSubmit={e => { e.preventDefault(); handleLogin(e); }}>
                            <input type="text" placeholder="Phone" className="modal-input" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            <input type="password" placeholder="Password" className="modal-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button type="submit" className="action-btn">Login</button>
                        </form>
                        <p className="modal-text">
                            Belum punya akun? <span onClick={openRegisterModal} className="modal-link">Register</span>
                        </p>
                    </div>
                </div>
            )}

            {isRegisterModalVisible && (
                <div className={`modal-overlay ${isRegisterOpen ? 'fade-in' : 'fade-out'}`}>
                    <div className={`modal-box ${isRegisterOpen ? 'zoom-in' : 'zoom-out'}`} ref={registerRef}>
                        <h2 className="modal-title">Register</h2>
                        <form className="modal-form" onSubmit={handleRegisterSubmit}>
                            <input type="text" placeholder="Nama Lengkap" className="modal-input" value={name} onChange={(e) => setName(e.target.value)} />
                            <input
                                type="text"
                                placeholder="Nomor Handphone"
                                className="modal-input"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onFocus={() => {
                                    if (!phoneNumber.startsWith("+62")) {
                                        setPhoneNumber("+62");
                                    }
                                }}
                            />
                            <p className="modal-note">Pastikan nomor yang Anda masukkan adalah nomor WhatsApp yang aktif.</p>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control rounded-pill shadow-sm border-0"
                                    placeholder="Masukkan password baru"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <ul className="password-criteria">
                                    {criteria.map((criterion) => (
                                        <li
                                            key={criterion.id}
                                            className={criterion.isValid ? 'text-success' : 'text-danger'}
                                        >
                                            {criterion.label}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <input type="password" placeholder="Konfirmasi Password" className="modal-input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            <button type="submit" className="action-btn" disabled={!isPasswordValid}>Register</button>
                        </form>
                        <p className="modal-text">
                            Sudah punya akun? <span onClick={switchToLoginModal} className="modal-link">Login</span>
                        </p>
                    </div>
                </div>
            )}

            {isOtpModalVisible && (
                <div className={`modal-overlay ${isOtpOpen ? 'fade-in' : 'fade-out'}`}>
                    <div className={`modal-box ${isOtpOpen ? 'fade-in' : 'fade-out'}`}>
                        <h2 className="modal-title">OTP Verification</h2>
                        <p className="modal-subtext">Silakan periksa WhatsApp Anda. Kami telah mengirimkan kode OTP.</p>
                        <form className="modal-form" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={otp}
                                onChange={handleChange}
                                maxLength={6}
                                placeholder="Masukkan 6 digit OTP"
                                className="modal-input"
                            />
                            {errorMessage && <p className="modal-error">{errorMessage}</p>}
                            <button type="submit" className="action-btn" disabled={isSubmitting}>
                                {isSubmitting ? 'Memverifikasi...' : 'Verifikasi'}
                            </button>
                        </form>
                        <p className="modal-text">
                            {resendTimer > 0 ? (
                                `Kirim ulang OTP dalam ${resendTimer} detik`
                            ) : (
                                <span onClick={handleResendOtp} className="modal-link">Kirim Ulang OTP</span>
                            )}
                        </p>
                    </div>
                </div>
            )}

            <footer id="footer">
                <svg
                    className="hero-waves m-0"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    style={{ rotate: '180deg' }}
                >
                    <defs>
                        <path
                            id="wave-path"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#ECF7F3" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                </svg>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <img src={logoFooter.value_} width="80" height="80" />
                                <div className="social-links mt-3">
                                    {socialMedia.map(x => {
                                        return (
                                            <a key={x.id} href={x.link} title={x.name}><i className={x.logo}></i></a>
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Site Map</h4>
                                <ul>
                                    <li><i className="bi bi-chevron-right me-1"></i><a href="/">Home</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Contact Us</h4>
                                <ul id="contactInfo"></ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        &copy; 2024 <strong><span id="footerProject"></span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
                className="bi bi-arrow-up-short"></i></a>
        </>
    )
}

export default Layout;