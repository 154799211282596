import { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment';
import { API_URL } from "../../../static/environment.js"
import { useParams } from "react-router-dom";

let Payment = () => {
    const [purchase, setPurchase] = useState();
    const [accountNumber, setAccountNumber] = useState("");
    const [dataType, setDataType] = useState("");
    const [countdown, setCountdown] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [imageSrc, setImageSrc] = useState("/assets/img/paid.png");
    const [isPaymentVisible, setIsPaymentVisible] = useState(true);

    const qrCodeRef = useRef(null);

    let { phone, invoice } = useParams();

    useEffect(() => {
        axios.get(`${API_URL}/purchase/${phone}/${invoice}`)
            .then(function (response) {
                const data = response.data;
                setPurchase(data);
                setDataType(data.paymentMethod.category);
                setAccountNumber(data.paymentNumber);

                const expiredValue = data.expiredDate;
                const countDownDate = new Date(expiredValue).getTime();

                if (data.status.name === "Expired") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/expired.png");
                } else if (data.status.name === "Paid") {
                    setIsPaymentVisible(false);
                } else if (data.status.name === "Success") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/success2.png");
                } else if (data.status.name === "Pending") {
                    setIsPaymentVisible(false);
                } else if (data.status.name === "Refunded") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/refunded.png");
                } else if (data.status.name === "Failed") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/failed.png");
                } else if (data.status.name === "Canceled") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/canceled.png");
                } else if (data.status.name === "Processing") {
                    setIsPaymentVisible(false);
                    setImageSrc("/assets/img/processing.png");
                } else {
                    const intervalId = setInterval(() => {
                        const now = new Date().getTime();
                        const distance = countDownDate - now;

                        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        setCountdown({ hours, minutes, seconds });

                        if (distance < 0) {
                            clearInterval(intervalId);
                            setCountdown({ hours: 0, minutes: 0, seconds: 0 });
                        }
                    }, 1000);

                    return () => clearInterval(intervalId); // Cleanup interval saat komponen unmount
                }
            })
            .catch(function (error) {
                window.alert(error);
            })
    }, [imageSrc]);

    const download = () => {
        const imageUrl = qrCodeRef.current.src;
        const filename = 'qr_code.png';

        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.error('Error downloading the image:', error));
    }

    const renderContent = () => {
        console.log("im rendered", dataType)
        if (dataType === 'Virtual Account' || dataType === "Convenience Store") {
            return (
                <>
                    <h6 className="opacity-50">Payment Number</h6>
                    <h4 id="copyClipboard" className="my-3 fw-bold">{accountNumber}</h4>
                    <button
                        id="btnClipboard"
                        className="btn btnPayment btn-outline-primary btn-sm py-2"
                        onClick={() => navigator.clipboard.writeText(accountNumber)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-copy me-1"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                        </svg>
                        Copy
                    </button>
                </>
            );
        } else if (dataType === 'QRIS' || dataType === "Bank") {
            return (
                <>
                    <img
                        id="qrCode"
                        ref={qrCodeRef}
                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${accountNumber}`}
                        alt="QR Code"
                        className="w-full img-thumbnail"
                    />
                    <br />
                    <br />
                    <button
                        id="btnDownload"
                        className="btn btnPayment btn-outline-primary btn-sm py-2"
                        onClick={() => download()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-download"
                            viewBox="0 0 16 16"
                        >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                        Download
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <h6 className="opacity-50">Continue Payment</h6>
                    <a href={accountNumber} className="btn btn-primary mt-2">
                        Go to Payment
                    </a>
                </>
            );
        }
    };

    return (
        <>
            <section id="hero">
                <div className="container py-5">
                    {purchase ? (
                        <div className="row">
                            <div className="col-lg-8 order-2">
                                <div data-aos="zoom-out" className="d-flex flex-column row-gap-4">
                                    <div className="col-12 product-page">
                                        <div className="card product-corner">
                                            <div className="card-header bg-secondary">
                                                <div className="d-flex align-items-center">
                                                    <h5 className="fw-bold m-0">Order</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row row-gap-3">
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                        <h6 className="opacity-75">Purchase Date</h6>
                                                        <h6 className="fw-bold">{moment(purchase.purchaseDate).format("DD MMM YYYY")}</h6>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                        <h6 className="opacity-75">Order Number</h6>
                                                        <h6 className="fw-bold">{purchase.id}</h6>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                        <h6 className="opacity-75">Payment Method</h6>
                                                        <h6 className="fw-bold">{purchase.paymentMethod.name}</h6>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                        <h6 className="opacity-75">Order Status</h6>
                                                        <h6 id="status" className="fw-bold status">{purchase.status.name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 product-page">
                                        <div className="card product-corner">
                                            <div className="card-header bg-secondary">
                                                <div className="d-flex align-items-center">
                                                    <h5 className="fw-bold m-0">Payment Detail</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center gap-4">
                                                    <img src={purchase.myItem.image.path} alt=""
                                                        className="img-fluid product-corner" width="60" height="60" />
                                                    <div data-aos="zoom-out">
                                                        <h5 className="fw-bold">{purchase.myItem.myProduct.title}</h5>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <div className="row row-gap-3">
                                                    <div className="d-flex flex-col justify-content-between">
                                                        <h6 className="opacity-75">Item</h6>
                                                        <h6 className="fw-bold">{purchase.myItem.name}</h6>
                                                    </div>
                                                    {purchase.trxFFAttributePurchases.map(x => {
                                                        return (
                                                            <>
                                                                <div className="d-flex flex-col justify-content-between">
                                                                    <h6 className="opacity-75">{x.ffattribute.value_}</h6>
                                                                    <h6 className="fw-bold">{x.value_}</h6>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                    <div className="d-flex flex-col justify-content-between">
                                                        <h6 className="opacity-75">Price</h6>
                                                        <h6 className="fw-bold" id="price">{purchase.price?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                    </div>
                                                    <div className="d-flex flex-col justify-content-between">
                                                        <h6 className="opacity-75">Fee</h6>
                                                        <h6 className="fw-bold" id="fee">{purchase.fee?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                    </div>
                                                    <div className="d-flex flex-col justify-content-between">
                                                        <h6 className="opacity-75">Unique Code</h6>
                                                        <h6 className="fw-bold">{purchase.uniqueCode?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <div className="d-flex flex-col justify-content-between">
                                                    <h6 className="opacity-75">Total Payment</h6>
                                                    <h6 className="fw-bold" id="totalPrice">{purchase.totalPrice?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 hero-img text-center" data-aos="zoom-out" data-aos-delay="300">
                                {isPaymentVisible ? (
                                    <div id="beforePayment" className="row row-gap-4">
                                        <div data-aos="zoom-out">
                                            <h2 className="text-white fw-bold">
                                                <span className="status"></span>
                                            </h2>
                                            <h6 className="text-white opacity-50">
                                                Complete Payment Before Time Runs Out
                                            </h6>
                                        </div>
                                        <div
                                            data-aos="zoom-out"
                                            className="d-flex gap-4 justify-content-center"
                                        >
                                            <div className="display-time">
                                                <h1 className="text-green fw-bold" id="hour">
                                                    {countdown.hours}
                                                </h1>
                                                <h6 className="text-white">Jam</h6>
                                            </div>
                                            <div className="display-time">
                                                <h1 className="text-green fw-bold" id="minute">
                                                    {countdown.minutes}
                                                </h1>
                                                <h6 className="text-white">Menit</h6>
                                            </div>
                                            <div className="display-time">
                                                <h1 className="text-green fw-bold" id="second">
                                                    {countdown.seconds}
                                                </h1>
                                                <h6 className="text-white">Detik</h6>
                                            </div>
                                        </div>
                                        <div data-aos="zoom-out">
                                            <h6 className="text-white opacity-50">
                                                So That Your Order Does Not Expired
                                            </h6>
                                        </div>
                                        <hr className="text-white" />
                                        <div className="card product-corner">
                                            <div id="paymentMethodDiv" className="card-body">
                                                {renderContent()}
                                            </div>
                                        </div>
                                        <hr className="text-white" />
                                        <section className="faq p-0">
                                            <div className="faq-list text-start">
                                                <ul>
                                                    <li data-aos="fade-up" className="product-corner">
                                                        <a
                                                            data-bs-toggle="collapse"
                                                            className="collapse"
                                                            data-bs-target="#faq-list-1"
                                                        >
                                                            {purchase.paymentMethod.instructionTitle}
                                                            <i className="bi bi-chevron-down icon-show"></i>
                                                            <i className="bi bi-chevron-up icon-close"></i>
                                                        </a>
                                                        <div
                                                            id="faq-list-1"
                                                            className="mt-2 collapse show"
                                                            data-bs-parent=".faq-list"
                                                        >
                                                            <div
                                                                id="instructionDetail"
                                                                className="lh-sm fw-normal opacity-75"
                                                                dangerouslySetInnerHTML={{ __html: purchase.paymentMethod.instructionDetail }}
                                                            ></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </section>
                                        <input id="paymentMethod" value={purchase.paymentNumber} hidden />
                                    </div>
                                ) : (
                                    <div id="afterPayment" className="row row-gap-4">
                                        <div className="card product-corner">
                                            <div className="card-body">
                                                <img
                                                    id="imagePayment"
                                                    src={imageSrc}
                                                    className="w-full"
                                                    width="200"
                                                    height="200"
                                                    alt="Payment Status"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : <h1>loading</h1>}

                </div>
            </section>
        </>

    )
}

export default Payment;