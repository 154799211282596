import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import { API_URL } from "../../../static/environment.js"

let History = () => {
    const [order, setOrder] = useState([]);

    let { phone } = useParams();

    useEffect(() => {
        axios.get(`${API_URL}/purchases/${phone}`)
            .then(function (response) {
                setOrder(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })
    }, []);

    return (
        <section id="hero">
            <div className="container py-5">
                <div data-aos="zoom-out">
                    <h2 className="text-white fw-bold">History Order</h2>
                    <div className="row row-gap-4">
                        {order.map(x => {
                            return (
                                <a href={`/history/${x.user.phoneNumber}/${x.id}`}>
                                    <div className="card product-corner history-card">
                                        <div className="card-body history-order">
                                            <div className="d-flex align-items-center gap-4">
                                                <img src={x.myItem.myProduct.logo.path} alt=""
                                                    className="img-fluid product-corner" width="100" height="100" />
                                                <div>
                                                    <h6 className="fw-bold">{x.myItem.name}</h6>
                                                    <h6>{x.myItem.myProduct.title}</h6>
                                                    <div className="d-flex">
                                                        <i className="bi bi-credit-card me-2"></i>
                                                        <h6>{x.paymentMethod.name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="history-order-date">
                                                <h6>{moment(x.purchaseDate).format('DD MMM YYYY')}</h6>
                                                <h6 className="badge bg-warning">{x.status.name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default History;